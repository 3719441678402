import { toElements } from '../utils';

export async function cf7() {
    const forms = toElements('div.wpcf7 > form');

    if (forms.length) {
        let { initForm } = await import(/* webpackMode: "lazy" */ './inc/cf7.async');

        forms.forEach(form => {
            initForm(form);
        });
    }
}
