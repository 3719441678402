/**
 * Note: this file only serves as entry point and imports all required functionality from modules.
 * These modules are responsible to check whether they are applicable on the current site and only then load their business logic.
 * This way, we can have the best of both worlds, tree-shaking and dynamic imports.
 *
 * When writing modules, make sure to 'register' them in the corresponding index.js and reference them only over this file.
 *
 * If a module is completely not used on a project, remove the corresponding function calls from here.
 */

// ////////////////////////////////////////////////////////////
// Framework initialization
import * as foundation from './_includes/foundation';

foundation.init();

// ////////////////////////////////////////////////////////////
// Security
import * as security from './lib/security';

security.initSecuringExternalLinks();

// ////////////////////////////////////////////////////////////
// Application setup
import * as application from './lib/application';

// register service worker in production mode
application.serviceWorker();

// optimized font loading
// fonts are defined in .fontsrc.json and automatically added to CONFIG
application.loadFonts(CONFIG.fonts);
delete CONFIG.fonts; // release memory, fonts are not needed anymore

// ux / accessibility features
application.showFocusOnTabKey();
application.fadePage();
application.fontSizeSwitcher();
application.initPrimaryNav();

application.globalState.set(
    'headroom',
    application.initHeadroom({
        // add the spacer only if there is no hero element
        prependSpacerTo: document.querySelector('.off-canvas-content .hero') ? false : '.off-canvas-content',
        breakpoint: 'desktop',
    }),
);

// init all scrolling belonging
application.autoScrolling({
    fromHashChanges: CONFIG.scrollFromHashChanges,
    getTargetId(hash) {
        return hash.replace(/#\/?/, '');
    },
});

// ////////////////////////////////////////////////////////////
// form helpers
import * as form from './lib/form';

// prevent mobile zoom in
form.preventMobileZoom();

// automatically attach a loading indicator to submit buttons on form submit
form.loadingIndicator({
    exclude: '.btn--search',
});

// enable validation
form.validation().then(() => {
    form.steps(); // validation needs to be in place before multi-step forms can be initialized
});

// enable automatic summaries
form.summaries();

// enable conditional inputs
form.conditionals();

// style advanced file inputs
form.fileInput({
    multipleFilesLabel: ':anz Dateien ausgewählt',
});

// enable multi file uploads
form.multiUpload();

// init cf7 functionality
form.cf7();

// ////////////////////////////////////////////////////////////
// layouts
import * as layouts from './lib/layouts';

layouts.masonry();

// ////////////////////////////////////////////////////////////
// browser alerts
import * as alerts from './lib/alerts';

alerts.oldBrowserAlert();

let waitForBorlabs = () => {
    let cookieAccordionTargets = document.querySelectorAll('.BorlabsCookie [data-cookie-accordion-parent]'),
        options = {
            attributes: true,
        },
        observer;

    if (!cookieAccordionTargets.length) {
        setTimeout(waitForBorlabs, 100);
        return;
    }

    observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
            if (mutation.attributeName === 'style') {
                document
                    .querySelector(
                        '.BorlabsCookie [data-cookie-accordion-target="' +
                            mutation.target.dataset.cookieAccordionParent +
                            '"]',
                    )
                    .setAttribute('aria-expanded', mutation.target.style.display !== 'none');
            }
        });
    });

    cookieAccordionTargets.forEach(target => {
        observer.observe(target, options);
    });

    // ensure that the focusable elements that are actually hidden are not able to gain focus by keyboard
    new MutationObserver(mutations => {
        mutations.forEach(mutation => {
            if (mutation.attributeName === 'aria-hidden') {
                if (mutation.target.getAttribute('aria-hidden') !== 'false') {
                    mutation.target.querySelectorAll('.container, a, button, input').forEach(el => {
                        el.tabIndex = -1;
                    });
                } else {
                    mutation.target.querySelectorAll('.container, a, button, input').forEach(el => {
                        el.tabIndex = '';
                    });
                }
            }
        });
    }).observe(document.querySelector('.cookie-box'), options);
};

waitForBorlabs();
